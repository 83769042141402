import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_USER_MANAGEMENT_REGISTRATION } from 'constants/api';
import { showToast } from 'store/toast';
import { clearLSUserData, setIsLoggedIn } from 'helpers';
import { storeName } from '../config';
import { ProfileRefreshTokensResponse } from '../interfaces';

interface RegistrationData {
  email: string;
  firstName: string;
  lastName: string;
  inviteToken: string | null;
}

export const registerExternalUser = createAsyncThunk<
  ProfileRefreshTokensResponse,
  RegistrationData
>(`${storeName}/registerExternalUser`, async (data, thunkAPI) =>
  api.axios
    .post(API_USER_MANAGEMENT_REGISTRATION, data)
    .then((res) => {
      thunkAPI.dispatch(
        showToast({
          severity: 'success',
          message: {
            id: 'pages.externalUserRegistered',
            defaultMessage:
              'Congratulations! You are successfully registered in MARS.',
          },
        }),
      );

      setIsLoggedIn(true);
      clearLSUserData(res.data.user.id, thunkAPI.dispatch);

      return res.data;
    })
    .catch((e) => {
      thunkAPI.dispatch(
        showToast({
          severity: 'error',
          message: e.response?.data.message || e.message,
        }),
      );
      return thunkAPI.rejectWithValue(e.response?.data);
    }),
);
