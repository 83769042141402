import { defineMessages } from 'react-intl';

export default defineMessages({
  name: {
    id: 'forms.name',
    defaultMessage: 'Name',
  },
  password: {
    id: 'forms.password',
    defaultMessage: 'Password',
  },
  repeatPassword: {
    id: 'forms.repeatPassword',
    defaultMessage: 'Repeat Password',
  },
  firstName: {
    id: 'forms.firstName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'forms.lastName',
    defaultMessage: 'Last Name',
  },
  email: {
    id: 'forms.email',
    defaultMessage: 'Email',
  },
  company: {
    id: 'forms.company',
    defaultMessage: 'Company',
  },
  position: {
    id: 'forms.position',
    defaultMessage: 'Position',
  },
  description: {
    id: 'forms.description',
    defaultMessage: 'Description',
  },
  weight: {
    id: 'forms.weight',
    defaultMessage: 'Weight (%)',
  },
  errorRequired: {
    id: 'forms.errorRequired',
    defaultMessage: 'Field is required',
  },
  errorPattern: {
    id: 'forms.errorPattern',
    defaultMessage: 'Incorrect format',
  },
  startDate: {
    id: 'forms.startDate',
    defaultMessage: 'Start Date',
  },
  endDate: {
    id: 'forms.endDate',
    defaultMessage: 'End Date',
  },
  goalSettingAt: {
    id: 'forms.goalSettingAt',
    defaultMessage: 'Scorecard goal setting',
  },
  reviewFeedbacksAt: {
    id: 'forms.reviewFeedbacksAt',
    defaultMessage: 'Review Scorecard and request feedbacks',
  },
  provideFeedbacksAt: {
    id: 'forms.provideFeedbacksAt',
    defaultMessage: 'Provide feedbacks and discuss with crew members',
  },
  finalizationAt: {
    id: 'forms.finalizationAt',
    defaultMessage: 'Scorecard finalization deadline',
  },
  datePlaceholder: {
    id: 'forms.datePlaceholder',
    defaultMessage: 'Jan 1, 2022',
  },
  errorDateRangeAligned: {
    id: 'forms.errorDateRangeAligned',
    defaultMessage: `End date must be after start date`,
  },
  errorDateInFuture: {
    id: 'forms.errorDateInFuture',
    defaultMessage: 'End date must be after today',
  },
  errorDateWrongFormat: {
    id: 'forms.errorDateWrongFormat',
    defaultMessage: 'Incorrect format',
  },
  errorMaxLength: {
    id: 'forms.errorMaxLength',
    defaultMessage: `{fieldName} must not exceed {maxLength} {maxLength, plural,
        one {character}
        other {characters}
      }`,
  },
  errorMinLength: {
    id: 'forms.errorMinLength',
    defaultMessage: `{fieldName} must not be less than {minLength} {minLength, plural,
        one {character}
        other {characters}
      }`,
  },
  errorCommonMaxLength: {
    id: 'forms.errorCommonMaxLength',
    defaultMessage: `Must not exceed {maxLength} {maxLength, plural,
        one {character}
        other {characters}
      }`,
  },
  errorCommonMinLength: {
    id: 'forms.errorCommonMinLength',
    defaultMessage: `Must not be less than {minLength} {minLength, plural,
        one {character}
        other {characters}
      }`,
  },
  errorAlphanumeric: {
    id: 'forms.errorAlphanumeric',
    defaultMessage: `Must contain only letter and numbers`,
  },
  errorOnlyAlphabet: {
    id: 'forms.errorAlphanumeric',
    defaultMessage: `Must contain only letter`,
  },
  errorPasswordMatch: {
    id: 'forms.errorAlphanumeric',
    defaultMessage: `Password and Repeat password should match`,
  },
  noOptions: {
    id: 'forms.noOptions',
    defaultMessage: 'No options',
  },
  untitledGoal: {
    id: 'forms.untitledGoal',
    defaultMessage: 'Untitled Goal',
  },
  untitledSubgoal: {
    id: 'forms.untitledSubgoal',
    defaultMessage: 'Untitled Subgoal',
  },
  summary: {
    id: 'forms.summary',
    defaultMessage: 'Summary',
  },
  assessmentScale: {
    id: 'forms.assessmentScale',
    defaultMessage: 'Assessment scale',
  },
  attainmentLevels: {
    id: 'forms.tooltip.attainmentLevels',
    defaultMessage: 'Attainment Levels:',
  },
  pmScaleDescription: {
    id: 'forms.tooltip.pmScaleDescription',
    defaultMessage:
      'Consider using it for self-assessment OKR-like targets with defined results for each level.',
  },
  standardScaleDescription: {
    id: 'forms.tooltip.standardScaleDescription',
    defaultMessage:
      'Consider using it for feedback-based goals and subgoals for team members to assess you.',
  },
  tenGradeScaleDescription: {
    id: 'forms.tooltip.tenGradeScaleDescription',
    defaultMessage:
      'The resulting 0-10 average score is mapped to the following attainment levels: <ul><li>0-5 - Below expectations - 80</li><li>5-7 - Could be improved - 90</li><li>7-9 - As expected - 100</li><li>9-10 - Above expectations - 115</li></ul>',
  },
  granularScaleDescription: {
    id: 'forms.tooltip.granularScaleDescription',
    defaultMessage:
      '75%, 80%, 85%, ... 115%, 120%, 125% {br}{br} Consider using it in case you have a goal which can be assessed with high accuracy.',
  },
  calculateSelfAssessment: {
    id: 'forms.calculateSelfAssessment',
    defaultMessage: 'Request & Calculate Self-Assessment',
  },
  calculateSelfAssessmentTooltip: {
    id: 'forms.calculateSelfAssessmentTooltip',
    defaultMessage:
      'Decide if you wish to include self-assessment scores for this goal provided by the Scorecard owner into goal score calculations. This is suitable when the goal by its nature is intended to be assessed by the Scorecard owner and Career Mentor only.',
  },
  allowScorecardOwnerEdits: {
    id: 'forms.allowScorecardOwnerEdits',
    defaultMessage: 'Allow Scorecard Owner Edits',
  },
  allowScorecardOwnerEditsTooltip: {
    id: 'forms.allowScorecardOwnerEditsTooltip',
    defaultMessage:
      'You can allow the Scorecard owner to edit this goal in his/her Scorecard when the Template is published. When disabled only Career Mentor / Line Manager can edit this goal in the employee Scorecard.',
  },
  allowComment: {
    id: 'forms.allowComment',
    defaultMessage: 'Request Goal Comment',
  },
  allowCommentTooltip: {
    id: 'forms.allowCommentTooltip',
    defaultMessage:
      'Select if you want reviewers to have an open-text feedback field to comment on the Goal or Subgoals assessment they are giving.',
  },
  npsShortDescription: {
    id: 'forms.npsShortDescription',
    defaultMessage: 'Industry-standard Net Promoter Score (NPS) metrics.',
  },
  textShortDescription: {
    id: 'forms.textShortDescription',
    defaultMessage:
      'Text feedback with no assessment scale or scores. Consider using it if you want to get feedback on specific non-measurable targets, additional feedback combined with other subgoals with measurable assessment scale type.',
  },
  npsScoreMapping: {
    id: 'forms.npsScoreMapping',
    defaultMessage:
      'The resulting NPS score is mapped to the following attainment levels:<ul><li>NPS below 60 - Below Expectations - 85</li><li>NPS 60-70 - As Expected - 100</li><li>NPS 70+ - Above Expectations - 115</li></ul>',
  },
  enableSelfAssessment: {
    id: 'forms.enableSelfAssessment',
    defaultMessage: 'Request Self-Assessment as a Reference',
  },
  enableSelfAssessmentTooltip: {
    id: 'forms.enableSelfAssessmentTooltip',
    defaultMessage:
      'Enable if this goal shall be assessed by the Scorecard Owner. Further, you can configure if provided self-assessment scores shall be included in overall Goal Score calculations or presented as a reference only.',
  },
  disableSelfAssessment: {
    id: 'forms.disableSelfAssessment',
    defaultMessage: 'Disable Self-Assessment',
  },
  disableSelfAssessmentTooltip: {
    id: 'forms.disableSelfAssessmentTooltip',
    defaultMessage:
      "When selected Scorecard owner won't be asked to self-assess all sub-goals for this goal.",
  },
  mustBeGreaterOrEqual: {
    id: 'forms.mustBeGreaterOrEqual',
    defaultMessage: 'Must not be less than {minValue}',
  },
  mustBeLessOrEqual: {
    id: 'forms.mustBeLessOrEqual',
    defaultMessage: 'Must not exceed {maxValue}',
  },
  incorrectFormat: {
    id: 'forms.incorrectFormat',
    defaultMessage: 'Incorrect format',
  },
  field: {
    id: 'forms.field',
    defaultMessage: 'Field',
  },
});
