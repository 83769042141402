import { User, UserType } from 'store/interfaces';

export function concatUserPosition(
  position: string = '',
  seniority: string | null = '',
) {
  return [position, seniority].filter((s) => !!s).join(', ');
}

export function getUserPosition(user: User) {
  if (user.type === UserType.external) {
    return user.position;
  }

  const lastRecord = user.workRecords?.slice(-1)[0];
  let record = lastRecord;
  if (record?.status === 'Inactive') {
    const activeRecord = user.workRecords?.find(
      ({ status }) => status === 'Active',
    );

    record = activeRecord ?? lastRecord;
  }

  const position = record?.position?.name;
  const seniority = record?.seniority;
  return concatUserPosition(position, seniority ?? '');
}
