import { Tooltip as MUITooltip, Box } from '@mui/material';
import { MouseEvent } from 'react';
import { TooltipProps } from './interfaces';

export default function Tooltip({
  children,
  variant = 'dark',
  componentsProps,
  sx,
  parentComponent = 'span',
  placement = 'top',
  withoutTooltip = false,
  ...rest
}: TooltipProps & {
  parentComponent?: React.ElementType;
  withoutTooltip?: boolean;
}) {
  const onClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  if (withoutTooltip) {
    return (
      <Box component={parentComponent} sx={sx}>
        {children}
      </Box>
    );
  }

  return (
    <MUITooltip
      componentsProps={{
        popper: { onClick },
        tooltip: { variant },
        arrow: { variant },
        ...componentsProps,
      }}
      placement={placement}
      {...rest}
    >
      <Box component={parentComponent} sx={sx}>
        {children}
      </Box>
    </MUITooltip>
  );
}
