import { ExternalInviteStatus, User, UserType } from 'store/interfaces';
import { Role } from 'store/profile';
import { ROLE_EMPLOYEE, ROLE_EXTERNAL_REVIEWER } from 'constants/roles';

interface CurrentUserRoles {
  isEmployee: boolean;
  isExternalReviewer: boolean;
}

export const isUserExternalBasedOnType = (user: User) =>
  user.type === UserType.external;

export const parseUserRoles = (roles: Role[] = []): CurrentUserRoles => ({
  isEmployee: !!roles.find((role) => role.alias === ROLE_EMPLOYEE),
  isExternalReviewer: !!roles.find(
    (role) => role.alias === ROLE_EXTERNAL_REVIEWER,
  ),
});

export const getUserFullName = (user?: User) => {
  if (!user) return '';

  if (user.type === UserType.internal) {
    return user.fullName;
  }

  if (user.firstName && user.lastName) {
    return [user.lastName, user.firstName].join(' ');
  }

  return user.email;
};

export const parseUserInviteStatus = (user: User) => {
  const isExternalUser =
    isUserExternalBasedOnType(user) || user.isNewExternalUser;

  const isPending =
    isExternalUser &&
    user.externalInviteStatus === ExternalInviteStatus.invited;
  const isDraft =
    isExternalUser && user.externalInviteStatus === ExternalInviteStatus.draft;
  const isAccepted =
    isExternalUser &&
    user.externalInviteStatus === ExternalInviteStatus.accepted;

  return {
    isExternalUser: !!isExternalUser,
    isPending: !!isPending,
    isDraft: !!isDraft || user.isNewExternalUser,
    isAccepted: !!isAccepted,
  };
};
