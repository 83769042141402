import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { lazy } from 'react';
import AuthContainer from 'components/AuthContainer';

import messages from './messages';

const RegistrationForm = lazy(() => import('./RegistrationForm.component'));

export default function SignUp() {
  return (
    <AuthContainer>
      <>
        <Typography variant="h5">
          <FormattedMessage {...messages.completeRegistration} />
        </Typography>
        <RegistrationForm />
      </>
    </AuthContainer>
  );
}
