import { Avatar, IconButton, ListItemAvatar, Typography } from '@mui/material';
import {
  concatUserPosition,
  getUserFullName,
  getUserPosition,
  parseUserInviteStatus,
  resizeImage,
} from 'helpers';
import { User, WorkRecord } from 'store/interfaces';
import { OverflowTip } from 'components/Tooltip';
import {
  List,
  ListItem,
  ListItemText,
  Position,
  StyledPrimaryText,
} from './UserSelectDialog.styled';
import { DeleteOutlineIcon } from '../Icons';
import {
  ExternalUserDraftLabel,
  ExternalUserLabel,
  ExternalUserPendingLabel,
} from '../Label';

interface Props {
  canEditReviewers: boolean;
  enableSelfAssessment: boolean;
  scorecardOwner: User;
  users: User[];
  workRecord: WorkRecord;
  onRemoveUser: (user: User) => void;
}

export default function ReviewersList({
  canEditReviewers,
  enableSelfAssessment,
  scorecardOwner,
  users,
  onRemoveUser,
  workRecord,
}: Props) {
  const removeUser = (userToRemove: User) => onRemoveUser(userToRemove);

  const ownerPosition = concatUserPosition(
    workRecord.position.name,
    workRecord.seniority,
  );

  return (
    <List>
      {canEditReviewers && enableSelfAssessment && (
        <ListItem
          key={scorecardOwner.id}
          id={`selected-user-${scorecardOwner.id}`}
        >
          <ListItemAvatar>
            <Avatar
              alt={scorecardOwner.fullName}
              src={resizeImage(scorecardOwner.avatar)}
            />
          </ListItemAvatar>
          <ListItemText
            disableTypography={false}
            primaryTypographyProps={{ variant: 'h8' }}
            secondaryTypographyProps={{ variant: 'body6' }}
            primary={scorecardOwner.fullName}
            secondary={
              <>
                {scorecardOwner.email}
                <Position>{ownerPosition}</Position>
              </>
            }
          />
        </ListItem>
      )}
      {users.map((user) => {
        const { isExternalUser, isDraft, isPending, isAccepted } =
          parseUserInviteStatus(user);
        const userPosition = getUserPosition(user);

        return (
          <ListItem
            key={user.id}
            id={`selected-user-${user.id}`}
            secondaryAction={
              <IconButton onClick={() => removeUser(user)}>
                <DeleteOutlineIcon color="primary" />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar alt={user.fullName} src={resizeImage(user.avatar)} />
            </ListItemAvatar>
            <ListItemText
              disableTypography={false}
              primaryTypographyProps={{ variant: 'h8' }}
              secondaryTypographyProps={{ variant: 'body6' }}
              primary={
                <StyledPrimaryText>
                  <Typography variant="h8" component="span">
                    {getUserFullName(user)}
                  </Typography>
                  {isExternalUser && <ExternalUserLabel />}
                  {isPending && <ExternalUserPendingLabel />}
                  {isDraft && <ExternalUserDraftLabel />}
                </StyledPrimaryText>
              }
              secondary={
                isExternalUser && !isAccepted ? null : (
                  <OverflowTip>
                    {user.email}
                    {userPosition && (
                      <Position>{getUserPosition(user)}</Position>
                    )}
                  </OverflowTip>
                )
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
}
