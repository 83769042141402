import FullScreenLayout from 'components/Layout';
import SignIn from 'pages/SignIn/SignIn.component';
import SignUp from 'pages/SignUp/SignUp.component';
import ResetPassword from 'pages/ResetPassword/ResetPassword.component';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Header from 'components/Header';
import {
  selectIsLoggedIn,
  selectIsProfileInit,
  selectUserCanUseApp,
} from 'store/profile/selectors';
import Toast from 'components/Toast';
import PageLoader from 'components/PageLoader';
import AppRoutes from 'components/AppRoutes';
import useErrorHandler from 'hooks/useErrorHandler';
import OptimisticLockingDialog from 'components/OptimisticLockingDialog';
import VersionsInfo from 'components/VersionsInfo';
import { Stack } from '@mui/material';

function App() {
  const loggedIn = useSelector(selectIsLoggedIn);
  const isInit = useSelector(selectIsProfileInit);
  const canUse = useSelector(selectUserCanUseApp);
  const location = useLocation();

  useErrorHandler();
  if (!isInit) {
    return <PageLoader open />;
  }

  const authComponents: Record<string, JSX.Element> = {
    '/registration': <SignUp />,
    '/reset-password': <ResetPassword />,
  };

  const AuthComponent = authComponents[location.pathname] || <SignIn />;

  return (
    <FullScreenLayout>
      {!loggedIn || !canUse ? (
        AuthComponent
      ) : (
        <Stack sx={{ minHeight: '95vh' }}>
          <Header />
          <AppRoutes />
          <VersionsInfo />
        </Stack>
      )}
      <Toast />
      <OptimisticLockingDialog />
    </FullScreenLayout>
  );
}

export default App;
