import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import { NoOptionsWrapper } from './NoOptions.styled';

interface Props {
  externalEmail: string | null;
  onExternalUserInvite: (externalEmail: string | null) => void;
}

export default function NoOptions({
  externalEmail,
  onExternalUserInvite,
}: Props) {
  return (
    <NoOptionsWrapper onClick={() => onExternalUserInvite(externalEmail)}>
      <FormattedMessage
        {...messages.inviteExternalUser}
        values={{
          externalEmail,
          b: (chunks) => (
            <strong style={{ display: 'inline' }}>{chunks}</strong>
          ),
        }}
      />
    </NoOptionsWrapper>
  );
}
