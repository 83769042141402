import { useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { LogoIcon } from 'components/Icons';
import {
  Background,
  Block,
  Container,
  LogoContainer,
  Form,
} from './AuthContainer.styled';

interface AuthHeroProps {
  children: ReactNode;
}

export default function AuthContainer({ children }: AuthHeroProps) {
  const theme = useTheme();

  return (
    <Background>
      <Container>
        <LogoContainer>
          <LogoIcon
            logoColor={theme.palette.common.white}
            textColor={theme.palette.common.white}
            width={200}
          />
        </LogoContainer>
        <Form>{children}</Form>
        <Block />
      </Container>
    </Background>
  );
}
