import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_SCORECARD_COPY } from 'constants/api';
import { generateURL } from 'helpers';
import { updateFullData } from './updateFullData';
import { showToast } from '../../toast';

export const copyScorecardGoal = createAsyncThunk<
  {},
  {
    id: number;
    srcGoalId: number;
    expectedVersion: number;
    goalName: string;
    reviewCycle: string;
  }
>(
  'scorecards/copy',
  async ({ id, srcGoalId, expectedVersion, goalName, reviewCycle }, thunkAPI) =>
    api.axios
      .patch(generateURL(API_SCORECARD_COPY, { id }), {
        srcGoalId,
        expectedVersion,
      })
      .then((res) => {
        thunkAPI.dispatch(
          showToast({
            severity: 'success',
            message: {
              id: 'pages.scorecards.copyGoalDialog.success',
              defaultMessage:
                '{goalName} successfully copied to {reviewCycle} Scorecard',
              values: {
                goalName,
                reviewCycle,
              },
            },
          }),
        );

        thunkAPI.dispatch(updateFullData({ id, store: 'scorecardsOwn' }));
        return res.data;
      })
      .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
