import { WorkRecord } from './WorkRecord';

export enum UserType {
  internal = 'internal_member',
  external = 'external_reviewer',
}

export enum ExternalInviteStatus {
  draft = 'draft',
  invited = 'invited',
  accepted = 'accepted',
}

export interface User {
  id: number | null;
  avatar: string | null;
  fullName: string;
  lastHeartbeatAt: string | null;
  email: string;
  isBanned: boolean;
  enabled: boolean;
  workRecords?: Omit<WorkRecord, 'user'>[];
  isNewExternalUser?: boolean;
  type?: UserType;
  firstName: string;
  lastName: string;
  position: string;
  company: string;
  externalInviteStatus?: ExternalInviteStatus;
}

export interface UserWithRecords extends User {
  workRecords: Omit<WorkRecord, 'user'>[];
}
