import { ProfileInfo, ProfileState } from './interfaces';

export const initialInfo: ProfileInfo = {
  id: null,
  avatar: null,
  email: '',
  enabled: false,
  isBanned: false,
  lastHeartbeatAt: null,
  username: '',
  fullName: '',
  roles: [],
  permissions: [],
  firstName: '',
  lastName: '',
  company: '',
  position: '',
};

export const profileInitialState: ProfileState = {
  loggedIn: false,
  init: false,
  info: { ...initialInfo },
  feedbackDialogActive: false,
  token: '',
  mailingSettings: {
    enabled: false,
    redirectToEmail: '',
    redirectToUser: undefined,
  },
};

export const storeName = 'profile';
