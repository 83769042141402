import { defineMessages } from 'react-intl';

export default defineMessages({
  firstNamePlaceholder: {
    id: 'pages.signup.firstNamePlaceholder',
    defaultMessage: 'Please provide your first name',
  },
  lastNamePlaceholder: {
    id: 'pages.signup.lastNamePlaceholder',
    defaultMessage: 'Please provide your last name',
  },
  completeRegistration: {
    id: 'pages.signup.completeRegistration',
    defaultMessage: 'Complete registration',
  },
});
