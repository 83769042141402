import { AppRouteComponentProps } from 'components/AppRoutes';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectProfileInfo } from 'store/profile';
import { parseUserRoles } from '../../helpers/users/roles';

export default function usePageAccessible({
  currentRoute: { roles, permissions, allPermissionsRequired = true },
}: AppRouteComponentProps) {
  const { roles: userRoles, permissions: userPermissions } =
    useSelector(selectProfileInfo);

  const { isEmployee, isExternalReviewer } = parseUserRoles(userRoles);

  return useMemo(() => {
    if (!roles?.length && !permissions?.length) {
      return true;
    }
    if (!isEmployee && !isExternalReviewer) {
      return false;
    }
    // This is temp. later we're gonna use permissions only.
    const isEnabledByRole = roles?.some((requiredRole) =>
      userRoles?.find((role) => role.alias === requiredRole),
    );
    const isEnabledByPermission = allPermissionsRequired
      ? permissions?.every((requiredPermission) =>
          userPermissions?.find(
            (permission) => permission.name === requiredPermission,
          ),
        )
      : permissions?.some((requiredPermission) =>
          userPermissions?.find(
            (permission) => permission.name === requiredPermission,
          ),
        );

    return Boolean(isEnabledByRole || isEnabledByPermission);
  }, [
    roles,
    permissions,
    userRoles,
    allPermissionsRequired,
    userPermissions,
    isEmployee,
    isExternalReviewer,
  ]);
}
