import { BoxProps } from '@mui/material';
import { useEffect } from 'react';
import { batch, useSelector } from 'react-redux';
import { ROLE_EMPLOYEE, ROLE_EXTERNAL_REVIEWER } from 'constants/roles';
import { errorTexts } from 'i18n';
import { useAppDispatch } from 'store';
import {
  updateOptions,
  updateAssessmentScales,
  updateParamTypes,
} from 'store/options';
import { selectRoles } from 'store/profile';
import { showToast } from 'store/toast';
import { summaryGet } from 'store/summary';
import { useSocketIo } from 'hooks';
import { Layout } from './Layout.styled';

export default function FullScreenLayout({ children, ...props }: BoxProps) {
  const dispatch = useAppDispatch();
  const roles = useSelector(selectRoles);

  useSocketIo();

  useEffect(() => {
    if (roles.length) {
      const isEmployee = roles.find((role) => role.alias === ROLE_EMPLOYEE);
      const isExternalUser = roles.find(
        (role) => role.alias === ROLE_EXTERNAL_REVIEWER,
      );

      if (!isEmployee && !isExternalUser) {
        dispatch(
          showToast({
            severity: 'error',
            autoHideDuration: null,
            message: errorTexts.noPermission,
          }),
        );
      } else {
        batch(() => {
          dispatch(updateOptions()).then(() => {
            dispatch(updateAssessmentScales());
            dispatch(updateParamTypes());
          });
          dispatch(summaryGet());
        });
      }
    }
  }, [dispatch, roles]);

  return <Layout {...props}>{children}</Layout>;
}
