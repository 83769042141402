import { AutocompleteChangeReason, Box, List, ListProps } from '@mui/material';
import { debounce } from 'debounce';
import { isEmpty } from 'lodash-es';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import SearchInput from 'components/SearchInput';
import { useHideTooltipsDuringScroll } from 'hooks';
import { SearchBoxProps } from './interfaces';
import { SearchLabel, Autocomplete, RequireMark } from './SearchBox.styled';

function SearchList(props: ListProps) {
  useHideTooltipsDuringScroll('.searchBoxList');
  return <List classes={{ root: 'searchBoxList' }} {...props} />;
}

interface SearchBoxCustomProps {
  noOptionsComponent?: any;
  noOptionsDisplayRule?: (a: string) => boolean;
  onSearchChange?: (search: string) => void;
}

export default function SearchBox<T>({
  searchLabel,
  searchPlaceholder,
  searchThreshold = 3,
  isRequired = false,
  onSearch,
  onItemSelect,
  filterOptions = (options) => options,
  getOptionLabel,
  renderOption,
  isDisabled,
  sx,
  isSlim = true,
  noOptionsComponent,
  noOptionsDisplayRule,
  onSearchChange,
}: SearchBoxProps<T> & SearchBoxCustomProps) {
  const [searchText, setSearchText] = useState('');
  const [prevSearchText, setPrevSearchText] = useState('');
  const [items, setItems] = useState<T[]>([]);
  const [isToShowNoOptionsComponent, setIsToShowNoOptionsCompoennt] =
    useState<boolean>(false);

  const clearSearch = useCallback(() => {
    setSearchText('');
    setPrevSearchText('');
    setItems([]);
  }, []);

  const checkIsToShowNoOptionsCompoennt = (data: any) => {
    if (isEmpty(data)) {
      if (noOptionsDisplayRule && noOptionsDisplayRule(searchText)) {
        return setIsToShowNoOptionsCompoennt(true);
      }
    }

    return setIsToShowNoOptionsCompoennt(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const search = useCallback(
    debounce((query: string) => {
      if (!query) {
        setPrevSearchText('');
        clearSearch();
      } else if (query.length >= searchThreshold && query !== prevSearchText) {
        setPrevSearchText(query);
        onSearch(query).then(async (data) => {
          checkIsToShowNoOptionsCompoennt(data);

          return setItems(data);
        });
      }
    }, 300),
    [onSearch, searchText],
  );

  useEffect(() => {
    clearSearch();
  }, [clearSearch]);

  useEffect(() => {
    if (onSearchChange) {
      onSearchChange(searchText);
    }

    search(searchText);
  }, [search, searchText, onSearchChange]);

  const onSearchTextChange = (event: SyntheticEvent, value: string) => {
    setSearchText(value);
  };

  const onOptionSelect = (
    event: SyntheticEvent<Element, Event>,
    value: unknown,
    reason: AutocompleteChangeReason,
  ) => {
    if (reason === 'selectOption') {
      onItemSelect(value as T);
    }
    setSearchText('');
    clearSearch();
  };

  const renderOptionFunc = isToShowNoOptionsComponent
    ? noOptionsComponent
    : renderOption;

  const getItemsList = (): any[] => {
    if (!isToShowNoOptionsComponent) return items;

    return noOptionsDisplayRule && noOptionsDisplayRule(searchText)
      ? [searchText]
      : items;
  };

  return (
    <Box sx={sx}>
      {searchLabel ? (
        <SearchLabel>
          {searchLabel}
          {isRequired && <RequireMark />}
        </SearchLabel>
      ) : null}
      <Autocomplete<T, boolean, boolean, boolean>
        freeSolo
        blurOnSelect={false}
        noOptionsText={null}
        fullWidth
        disabled={isDisabled}
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
        options={getItemsList()}
        inputValue={searchText}
        onInputChange={onSearchTextChange}
        value={null}
        onChange={onOptionSelect}
        ListboxComponent={SearchList}
        renderOption={renderOptionFunc}
        renderInput={(params) => (
          <SearchInput
            {...params}
            slim={isSlim}
            placeholder={searchPlaceholder}
            InputProps={{
              ...params.InputProps,
              placeholder: searchPlaceholder,
            }}
          />
        )}
        disablePortal={false}
      />
    </Box>
  );
}
