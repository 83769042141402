import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'components/Dialog';
import {
  Box,
  Avatar,
  Button,
  IconButton,
  ListItemAvatar,
  Typography,
} from '@mui/material';
import { commonTexts, reviewCyclesTexts } from 'i18n';
import PageLoader from 'components/PageLoader';
import { useUserSearch, useMailingSettingsDialog } from 'hooks';
import { getUserPosition, resizeImage } from 'helpers';
import SearchBox from 'components/SearchBox/SearchBox.component';
import { User } from 'store/interfaces';
import UserListItem from 'components/UserListItem';
import { ListItem, ListItemText, Position } from 'components/UserSelectDialog';
import { DeleteOutlineIcon } from 'components/Icons';
import { OverflowTip } from 'components/Tooltip';
import { MailingSettingsDialogProps } from './interfaces';

export default function MailingSettingsDialogComponent({
  isEmailEnabled,
  onClose,
  email,
}: MailingSettingsDialogProps) {
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const { formatMessage } = useIntl();
  const { search } = useUserSearch();
  const { onSubmit, isLoading } = useMailingSettingsDialog(
    onClose,
    search,
    setSelectedUser,
    email,
    isEmailEnabled,
  );

  const onSearch = (query: string) => search({ searchText: query });

  return (
    <Dialog open onClose={onClose} fullWidth>
      <DialogTitle onClose={onClose}>
        <FormattedMessage
          id="technicalAdmin.emailsRecipient"
          defaultMessage="Notifications Recipient"
        />
      </DialogTitle>
      <DialogContent>
        <Typography variant="body4" mb={1.5}>
          <FormattedMessage
            id="technicalAdmin.emailsMessage"
            defaultMessage="To verify Notifications (text or sending) on the lower environments search for your Name and apply.
             {br} Nottifications for the appropriate actions will be sent to your mail-box.
             {br}{br} 'Email Verification' action icon will be highlighted in red as a reminder.
             {br}{br} To turn off notifications sending after verification remove your Name and apply."
            values={{ br: <br /> }}
          />
        </Typography>
        <Box mb={1.5}>
          <SearchBox<User>
            isDisabled={!!selectedUser}
            searchPlaceholder={formatMessage(reviewCyclesTexts.searchPeople)}
            onItemSelect={setSelectedUser}
            getOptionLabel={(user) =>
              typeof user === 'string' ? user : user.fullName
            }
            renderOption={(props, user) => (
              <UserListItem
                key={`search-option-${user.email}`}
                {...props}
                recipient={{
                  user,
                  position: { id: 0, name: getUserPosition(user) },
                }}
              />
            )}
            onSearch={onSearch}
          />
        </Box>
        {selectedUser && (
          <ListItem
            secondaryAction={
              <IconButton onClick={() => setSelectedUser(null)}>
                <DeleteOutlineIcon />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar
                alt={selectedUser.fullName}
                src={resizeImage(selectedUser.avatar)}
              />
            </ListItemAvatar>
            <ListItemText
              disableTypography={false}
              primaryTypographyProps={{ variant: 'h8' }}
              secondaryTypographyProps={{ variant: 'body6' }}
              primary={selectedUser.fullName}
              secondary={
                <OverflowTip>
                  {selectedUser.email}
                  <Position>{getUserPosition(selectedUser)}</Position>
                </OverflowTip>
              }
            />
          </ListItem>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} variant="outlined">
          <FormattedMessage {...commonTexts.cancel} />
        </Button>
        <Button
          disabled={isLoading}
          onClick={() =>
            onSubmit({
              enabled: !!selectedUser,
              redirectToUserId: selectedUser?.id,
            })
          }
          variant="contained"
        >
          <FormattedMessage {...commonTexts.apply} />
        </Button>
      </DialogActions>
      <PageLoader open={isLoading} />
    </Dialog>
  );
}
