import { Chip, styled, Theme } from '@mui/material';

const UserLabel = styled(Chip)(({ theme }: { theme: Theme }) => ({
  padding: '1px 8px',
  borderRadius: 4,
  height: 'auto',
  '.MuiChip-label': {
    padding: 0,
    ...theme.typography.body5,
  },
}));

export const StyledExternalUserLabel = styled(UserLabel)(({ theme }) => ({
  backgroundColor: theme.palette.grey['300'],
  color: theme.palette.common.black,
}));

export const StyledExternalUserPendingLabel = styled(UserLabel)(
  ({ theme }) => ({
    backgroundColor: theme.palette.yellow['500'],
    color: theme.palette.common.black,
  }),
);
