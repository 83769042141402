import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { lazy, useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import AuthContainer from 'components/AuthContainer';

const EmailForm = lazy(() => import('./EmailForm.component'));
const NewPasswordForm = lazy(() => import('./NewPasswordForm.component'));

export default function ResetPassword() {
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      try {
        const decoded: { email?: string } = jwtDecode(token);
        if (decoded.email) {
          setEmail(decoded.email);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to decode token');
      }
    }
  }, [setEmail]);

  return (
    <AuthContainer>
      <>
        {email ? (
          <Typography variant="h5">
            <FormattedMessage
              id="pages.resetPassword.newPasswordEmail"
              defaultMessage="New password for {userEmail}"
              values={{
                userEmail: email,
              }}
            />
          </Typography>
        ) : (
          <>
            <Typography variant="h5">
              <FormattedMessage
                id="pages.resetPassword.title"
                defaultMessage="Reset password"
              />
            </Typography>
            <Typography variant="body5">
              <FormattedMessage
                id="pages.resetPassword.enterEmailText"
                defaultMessage="Please enter your Email and we will send you a link to reset your password."
              />
            </Typography>
          </>
        )}
        {email ? <NewPasswordForm /> : <EmailForm />}
      </>
    </AuthContainer>
  );
}
