import { AppRouteItem } from 'components/AppRoutes';
import { Navigate, Route } from 'react-router-dom';
import { useMemo } from 'react';
import appRoutes from 'components/AppRoutes/routes';
import useAccessibleRoutes from './useAccessibleRoutes';

function buildRoutes(items: AppRouteItem[], redirect: string) {
  if (items.length) {
    const rootRedirect = redirect;
    const res = items.map((currentRoute) => {
      const { path, routes, Component } = currentRoute;
      const routeProps = {
        path,
        key: path,
      };
      if (Component) {
        Object.assign(routeProps, {
          element: (
            <Component
              rootRedirect={rootRedirect}
              currentRoute={currentRoute}
              routes={items}
            />
          ),
        });
      }
      if (routes?.length) {
        routeProps.path += '/*';
        Object.assign(routeProps, {
          children: buildRoutes(routes, routes[0].path),
        });
      }
      return <Route {...routeProps} />;
    });
    res.push(
      <Route
        path="*"
        key="redirect"
        element={<Navigate to={rootRedirect} replace />}
      />,
    );
    return res;
  }
  return null;
}

export default function useAppRoutes() {
  const accessibleRoutes = useAccessibleRoutes();

  return useMemo(
    () => buildRoutes(appRoutes, accessibleRoutes[0]?.path),
    [accessibleRoutes],
  );
}
