import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_PASSWORD_RESET } from 'constants/api';
import { showToast } from 'store/toast';
import { storeName } from '../config';
import { ProfileRefreshTokensResponse } from '../interfaces';

interface PasswordResetData {
  resetToken: string;
  password: string;
}

export const resetPasswordConduct = createAsyncThunk<
  ProfileRefreshTokensResponse,
  PasswordResetData
>(`${storeName}/resetPasswordConduct`, async (data, thunkAPI) =>
  api.axios
    .post(`${API_PASSWORD_RESET}/conduct`, data)
    .then((res) => {
      thunkAPI.dispatch(
        showToast({
          severity: 'success',
          message: {
            id: 'pages.resetPassword.passwordChanged',
            defaultMessage: 'New password is successfully saved',
          },
        }),
      );

      return res.data;
    })
    .catch((e) => {
      thunkAPI.dispatch(
        showToast({
          severity: 'error',
          message: e.response?.data.message || e.message,
        }),
      );
      return thunkAPI.rejectWithValue(e.response?.data);
    }),
);
