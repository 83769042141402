import { defineMessages } from 'react-intl';

export default defineMessages({
  addReviewers: {
    id: 'pages.scorecards.noReviewersAdded',
    defaultMessage: 'Add Reviewers',
  },
  inviteExternalUser: {
    id: 'pages.scorecards.inviteExternalUser',
    defaultMessage:
      'User with <b>{externalEmail}</b> email is not registered in the system.' +
      ' You can invite such users by adding them into the list of reviewers and once feedbacks are requested' +
      ' they will receive an invite. Click to add user.',
  },
});
