import { FormattedMessage } from 'react-intl';

import {
  StyledExternalUserLabel,
  StyledExternalUserPendingLabel,
} from './ExternalUserLabels.styled';

export function ExternalUserLabel() {
  return (
    <StyledExternalUserLabel
      label={
        <FormattedMessage
          id="externalUser.title"
          defaultMessage="External User"
        />
      }
    />
  );
}

export function ExternalUserPendingLabel() {
  return (
    <StyledExternalUserPendingLabel
      label={
        <FormattedMessage
          id="externalUser.pending"
          defaultMessage="Pending approval"
        />
      }
    />
  );
}

export function ExternalUserDraftLabel() {
  return (
    <StyledExternalUserPendingLabel
      label={
        <FormattedMessage
          id="externalUser.notInvited"
          defaultMessage="Not invited yet"
        />
      }
    />
  );
}

export default ExternalUserLabel;
