import { createSlice, isAnyOf, Reducer } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash-es';
import { storeName, profileInitialState } from './config';
import {
  profileRefreshTokens,
  profileUniversalLogIn,
  toggleFeedbackDialog,
  logout,
  profileSet,
  profileInit,
  getMailingSettings,
  setMailingSettings,
} from './actions';
import addCommonCases from '../addCommonCases';
import { registerExternalUser } from './actions/registerExternalUser';

export * from './interfaces';
export * from './actions';
export * from './selectors';

const profileSlice = createSlice({
  name: storeName,
  initialState: cloneDeep(profileInitialState),
  reducers: {},
  extraReducers(builder) {
    addCommonCases(builder, profileInitialState).addCase(
      toggleFeedbackDialog,
      (state, action) => {
        state.feedbackDialogActive = action.payload;
      },
    );
    builder.addMatcher(
      isAnyOf(
        profileRefreshTokens.rejected,
        profileUniversalLogIn.rejected,
        logout.fulfilled,
        logout.rejected,
        profileInit.rejected,
      ),
      (state) => {
        state.init = true;
      },
    );
    builder.addMatcher(
      isAnyOf(
        logout.fulfilled,
        logout.rejected,
        profileRefreshTokens.rejected,
        profileUniversalLogIn.rejected,
      ),
      (state) => {
        Object.assign(state, {
          ...profileInitialState,
          init: true,
          mailingSettings: state.mailingSettings,
        });
      },
    );
    builder.addMatcher(
      isAnyOf(
        profileRefreshTokens.fulfilled,
        profileUniversalLogIn.fulfilled,
        registerExternalUser.fulfilled,
        profileSet,
      ),
      (state, action) => {
        Object.assign(state, {
          info: action.payload.user,
          init: true,
          loggedIn: true,
          token: action.payload.auth.token,
        });
      },
    );
    builder.addMatcher(
      isAnyOf(setMailingSettings, getMailingSettings.fulfilled),
      (state, action) => {
        state.mailingSettings = action.payload;
      },
    );
  },
});

export default profileSlice.reducer as Reducer<typeof profileInitialState>;
