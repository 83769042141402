import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_PASSWORD_RESET } from 'constants/api';
import { showToast } from 'store/toast';
import { storeName } from '../config';
import { ProfileRefreshTokensResponse } from '../interfaces';

interface PasswordResetData {
  email: string;
}

export const resetPasswordInit = createAsyncThunk<
  ProfileRefreshTokensResponse,
  PasswordResetData
>(`${storeName}/resetPasswordInit`, async (data, thunkAPI) =>
  api.axios
    .post(`${API_PASSWORD_RESET}/init`, data)
    .then((res) => {
      thunkAPI.dispatch(
        showToast({
          severity: 'success',
          message: {
            id: 'pages.resetPassword.emailSent',
            defaultMessage:
              "Email was successfully sent to your account. Please check the Spam folder in case you haven't received it.",
          },
        }),
      );

      return res.data;
    })
    .catch((e) => {
      thunkAPI.dispatch(
        showToast({
          severity: 'error',
          message: e.response?.data.message || e.message,
        }),
      );
      return thunkAPI.rejectWithValue(e.response?.data);
    }),
);
